@import './options.scss';
@import './base/button.scss';
@import './base/animation.scss';

body{
    height: unset;
}

.iconfont {
    user-select: none;
}

.fcc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fbc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fc {
    display: flex;
    align-items: center;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-box{
    display: flex;
    align-items: center;
}

* {
    text-decoration: none;
    // font-family: Helvetica, Arial, sans-serif;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    // transition: all .3s;
}

span {
    color: inherit;
    font-size: inherit;
}

*>div {
    font-size: inherit;
}

.mask {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(66, 66, 66, 0.5);
    z-index: 99;
    // animation: maskFadeIn 0.3s linear forwards;

    .maskinner {
        max-width: 50%;
        position: relative;

        @media (max-width: 576px) {
            max-width: 95%;
        }

        @media (max-width: 1280px) {
            max-width: 80%;
        }

        button {
            border: none;
            outline: none;
            border-radius: 5px;
            font-size: 14px;
            padding: 0 15px;
            line-height: 1.5rem;

            &.active {
                color: white !important;
                background: #fd5356 !important;

                &:hover {
                    background: #eba2a2 !important;
                }
            }
        }

        input {
            border-color: rgba(217, 217, 217, 1);

            &:hover,
            &:focus {
                border-color: #fd5356;
            }
        }
    }
}

#contentWrap {
    @media (min-width: 1200px) {
        margin-left: 228px;
        // max-width: 1140px;
    }

    @media (max-width: 1650px) {
        margin-left: 228px;
        // max-width: 1140px;
    }

    @media (max-width: 992px) {
        margin-left: auto;
    }

    margin: 0 auto;
    transition: all 0.3s;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.selectPanel {
    animation: menuOpen .3s ease 0s forwards;

    div {
        min-height: 40px;

        div {
            display: flex;
            align-items: center;
            padding-left: 10%;
        }
    }
}

@page {
    // size: 5cm 20cm;
}

.select-hover:hover {
    background: $red;
    color: #fff;

    * {
        color: #fff;

    }
}

// .hover-text-white{

// }
button {
    border: none;
    outline: none;
    padding: 4px 11px;
    font-size: 14px;
}

input {
    outline: none;
}

input,
button {
    border-style: solid;
}

a {
    &:hover {
        text-decoration: none
    }
}

@media (max-width: 576px) {
    .sm-hide {
        display: none
    }
}

.img-wrap {
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.icon-xingzhuang::before {
    animation: rotate 1s infinite linear;
    display: block
}

::-webkit-scrollbar {
    width: 6px;
    // background: #fd5356
}

::-webkit-scrollbar-thumb {
    background: #fd5356;
    border-radius: 5px
}

@media print {
    #footer {
        display: none
    }
}
@page {
    margin: 0;
    font-family: SimSun,FangSong,STSong,STZhongsong,Microsoft YaHei, !important;
}

.img-center {
    object-fit: cover;
    width: inherit;
    height: inherit;
}

.single-text-hidden {
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.hide-scrollbar {
    &::-webkit-scrollbar {
        width: 0
    }
}

.fixedBG {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    top: 0;
    left: 0;
}

.cur-pointer {
    cursor: pointer;
}

.shadow-menu {
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 999;
    padding: 4px 0;

    &__item {
        padding: 5px 12px;
        font-size: 14px;
        color: rgba(0, 0, 0, .65);

        &:hover {
            background: $lightred;
        }
    }

    animation: menuOpen .3s ease forwards;
}

.skeleton {
    height: 1rem;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 50%, #f2f2f2 75%);
    animation: skeleton 4s linear infinite;
    background-size: 400%;
}

.PageContent {
    display: flex;
}

.sidebarRight {
    $sidebarRightWidth: 228px;
    width: $sidebarRightWidth;
    min-width: $sidebarRightWidth;
    min-height: 100vh;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(237, 237, 237, 1);
    position: relative;
    @media (max-width: 800px) {
        display: none;
    }
    @media print {
        display: none;   
    }

    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__fix{
        position: fixed;
        width: $sidebarRightWidth - 20px;
    }

    &__userdata {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        width: 100%;
    }

    &__text {
        color: #333;
        flex: 1;
    }

    &__username {
        font-size: 14px;
        font-weight: 500;
    }

    &__logo {
        $userlogoSize: 33px;
        width: $userlogoSize;
        height: $userlogoSize;
        margin-right: 1rem;

        img {
            border-radius: 50%;
        }
    }

    &__control {
        border-radius: 7px;
        width: 100%;
        border: 1px solid rgba(204, 204, 204, 1);
        padding: 15px;
        color: $red;

        div{
            flex: 1;
            text-align: center;
        }

        span{
            display: inline-block;
            width: 1px;
            background: $red;
            height: 12px
        }
    }
}

.disableInput{
    background: #f2f2f2;
    padding: 4px 11px;
    border: 0;
    border-radius: 7px;
    &:hover{
        box-shadow: transparent;
    }
}