// $red: #fd5356 ;
// $lightred: #fffdfd;
@import '../../data/options.scss';

#sidebar {
    user-select: none;
    z-index: 50;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition: .2s linear;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(208, 208, 208, 0.50);
    padding-top: 30px;

    &>div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .navList {
        margin-top: 2rem;
        color: #999;

        a{
            color: inherit;
            // padding: 20px;
        }

        .active{
            // background: $lightred;
            color: $red;
        }
        &>* {
            width: 100%;
            font-size: 16px;

            &:hover {
                text-decoration: none;
            }
        }

        i{
            margin-right: .5rem;
        }
    }

    @media print {
        display: none;
    }
}

#toggleSidebarIcon {
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 2000;
    width: 20px;
    height: auto;

    @media print {
        display: none;
    }
}

