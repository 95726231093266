@keyframes maskFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes skeleton{
    0%{
        background-position: 200%;
    }
    100%{
        background-position: -200%;

    }
}

@keyframes menuOpen {
    from{
        transform-origin: top;
        opacity: 0;
        transform: scaleY(0);
    }
    to{
        transform-origin: top;
        opacity: 1;
        transform: scaleY(1);

    }
}


@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes pulldown {
    0% {
        height: 1.5em;
        overflow: hidden;
        opacity: 0;
    }

    99% {
        opacity: 0.9;
    }

    100% {
        height: 4em;
        opacity: 1;
        overflow: unset;
    }
}