$ctrlFs: 24px;
$ctrlFs_md : 18px;
$ctrlFs_sm : 14px;
$red: #fd5356;

#orderList {
    min-height: 100vh;
    max-width: 986px;
    padding:0 15px;
    margin: 0 auto;

    .ctrlPanel {
        // margin-top: 30px;
        background: white;
        padding: 30px 0;
        min-height: 50px;
        box-sizing: content-box;
        flex-wrap: wrap;

        &>div {
            height: 100%
        }

        label {
            margin: 0
        }

        .search {
            min-width: 16rem;
        }

        @media (max-width: 730px) {
            .search {
                margin-top: .6rem;
            }
        }

        * {
            font-size: $ctrlFs;

            .icon-seekicon {
                font-size: $ctrlFs;
            }

            @media (max-width: 1100px) {
                font-size: $ctrlFs_md;

                &.orderStatus {
                    min-width: 62px;

                    div {
                        min-width: 62px;
                    }
                }
            }

            @media (max-width: 500px) {
                font-size: $ctrlFs_sm;
            }
        }
    }

    input[type='search'] {
        outline: none;
        padding: 0 1.5em;
        border-radius: 20px;
        height: 40px;
        border: #949497 1px solid;
        transition: all .4s;
        position: absolute;
        right: 20px;
        box-sizing: border-box;
        width: 0
    }

    .orderStatus {
        display: flex;
        justify-content: center;
        background: white;

        div {
            min-width: 80px;
            box-sizing: border-box;

            span {
                display: inline-block;
                color: #a3a3a3;
                padding: 0 20px;

                @media (max-width: 500px) {
                    padding: 0 10px
                }
            }

            &>span.active {
                &::after {
                    content: '';
                    display: block;
                    background: $red;
                    position: absolute;
                    width: 100%;
                    height: 5px;
                    bottom: -5px;
                    left: 0;
                }

                position: relative;
                color:$red;
            }
        }
    }

    .orderlistitem {
        td {
            padding: 8px 20px
        }

        .maskinner td {
            padding: unset;
        }

        button {
            background: transparent;
            border: 1px solid;
            border-color: $red;
            color: $red;
            outline: none;
            margin-left: 10px;
            font-size: 14px;
            height: 2em;
            border-radius: 5px;
            min-width: 6em;
        }

        .orderDate {
            min-width: 140px;
            padding-left: 20px;

            @media (max-width: 500px) {
                padding-left: 0;
                min-width: 300px;
            }
        }
    }
}

// @media screen {
.order-print {
    display: none;
}

// }
// @media print {
.order-print {
    width: 58mm;
    display: block !important;
    color: #333333;
    line-height: 22px;

    hr {
        margin: .3rem 0
    }

    .name {
        font-size: 14px;
        display: inline-block;

        div {
            display: inline-block;
        }
    }

    table {
        table-layout: fixed;
        word-break: break-all;
        width: 100%;

        tr {
            td:nth-child(1) {
                width: 50%;
            }
        }
    }
}

@media print {
    .print-hide {
        display: none;
    }
}

// }
#order_detail {
    padding: 10px 20px 50px;
    margin: 0;

    * {
        font-size: 14px;
    }

    .title {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .detail_panel {
        border: #c0c0c0 solid 1px;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 30px;

        .data>div {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 5px;

            div:nth-child(1) {
                color: #c0c0c0;
            }
        }
    }

    .col-12 {
        margin-bottom: 20px;
    }

    table {
        border-collapse: separate;
        table-layout: fixed;
        width: 100%;
        border-spacing: 0 10px;

        td:nth-child(1) {
            width: 50%;
        }
    }
}

// #shipPanel{
//     display: flex;
//     @media (max-width:) {

//     }
// }
#exportOrder {
    input {
        border: #949497 1px solid;

        &:hover {
            border-color: #fd5356
        }
    }
}

ol#batch-order {
    &>* {
        margin-bottom: 1rem;
    }
}

.TuanOrder {
    padding-top: 50px;

    &__sellerList {
        background: #fff;
        padding: 30px;
        margin: 30px;
        box-shadow: 0px 0px 12px 0px rgba(219, 219, 219, 0.5);

        @media (max-width: 576px) {
            margin: 20px 10px;
            padding: 12px;
        }

        &__title {
            border-bottom: 1px #eee solid;
            color: #333;
            font-weight: 600;
            font-size: 28px;
            padding-bottom: 12px;
        }

        &__item {
            margin-top: 16px;
            padding-bottom: 12px;
            border-bottom: 1px solid #ddd;

            &__head {
                display: flex;
                align-items: center;
                padding: 12px 0;

                &__ava {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 12px;
                }

                &__username {
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            &__list {
                background: #fafafa;
                padding: 12px;

                &__item {
                    border-bottom: 1px solid #eee;

                    &__logo {
                        max-width: 30px;
                        width: 30px;
                        height: 30px;
                        margin: 6px 12px 6px 0;
                    }

                    &__name {
                        flex: 1;
                    }

                    &__price {
                        color: $red
                    }

                    &__num {
                        min-width: 3em;
                        text-align: right;
                    }
                }
            }

            &__foot {
                flex-wrap: wrap;
                margin-top: 12px;

                &__toDetail {
                    color: $red;
                    font-size: 12px;
                }

                &__totalData {
                    font-size: 12px;
                }

                &__totalPrice {
                    color: $red;
                }
            }
        }
    }
}

.TuanOrderList {
    padding: 36px 30px;

    @media (max-width: 576px) {
        padding: 60px 12px;
    }

    &__list {
        margin-top: 30px;
        position: relative;
    }

    &__selectTuan {
        color: #353535;
        padding: 6px 1em;
        margin: 2px -12px;
        width: 14em;

        &:hover {
            background: $red;
            color: #fff;
        }

        &_active {
            background: $red;
            color: #fff;
        }
    }
}

.order__analysis {
    &__title {
        margin-bottom: 4rem;
    }

    padding: 50px;

    &__total {
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 1.4rem;
        border-radius: 5px;
        margin-bottom: 2rem;

        &__item {
            flex-basis: 33%;
            display: flex;
            align-items: baseline;
            margin: .3rem 0;

            &__name {
                min-width: 6em;
            }

            &__price {
                font-size: 1.3rem;
            }
        }
    }

    &__goodtypeChange {
        display: flex;
        align-items: center;
        margin: 1rem 0;
    }

    &__goodtype {
        border-radius: 4px;
        padding: 4px 11px;
        border: 1px solid #eee;
        color: #999;
        margin-right: .6rem;
        cursor: pointer;

        &_active {
            color: #333;
            border-color: #666;
        }
    }

    &__list {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1rem;

        td,
        th {
            border: 1px solid #eee;
            padding: .4rem .4rem;

            &:nth-child(2) {
                width: 5em;
            }

            &:nth-child(3) {
                width: 4em;
            }
        }

        thead {
            tr {
                background: #f7f8f9;
            }
        }

        tr:nth-child(2n) {
            background: #f7f8f9;
        }
    }
}

.orderList {
    &__export {
        margin: 12px;
        margin-right: 0;
    }
}

.orderItem {
    table {
        td {
            border: 1px solid rgb(246, 246, 246);
        }
    }

    &__goodName {
        width: 45%;
        border-bottom: 1px solid #f6f6f6;
        padding: 8px 20px;
        @media screen and (max-width: 556px) {
            width: 25%;
        }

        &>div {
            width: 100%;

            img {
                @media screen and (max-width: 556px) {
                    display: none
                }
                width: 60px;
                height: 60px;
                margin-right: 1rem;
            }

            div:nth-child(2) {
                flex: 1;

                span:nth-child(1) {
                    color: #333;
                }

                span:nth-child(2) {
                    color: #999;
                }
            }
        }
    }

    &__price {
        width: 8%;
        color: #333;
    }

    &__num{
        color: $red;
        width: 5%;
    }

    &__totalPrice{
        padding: 1rem;
        text-align: center;
        font-size: 12px;
        color: #333;
    }

    &__mchRemark{
        // padding: .3rem 1rem 1.2rem;
        // border-top: none!important;
        margin-top: .4rem;
        margin-bottom: 1rem;
            background: #FFF7EA;
            color:#F7B441;
            border-radius: 4px;
            padding: .5rem 1rem;
    }
}